import React from 'react';
import Layout from '../components/layout/Layout';
import css from 'styled-components';

import imgBystack from '../images/bystack.png';

const Wrap = css.div`
  width: 1200px;
  margin: 60px auto;
  h1{
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    margin-bottom: 50px;
  }
  h2{
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    margin: 0 0 10px;
  }
  p{
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin: 30px 0;
  }
  img{
    width: 100%;
  }
`;

const Article = () => {
  return (
    <Layout>
      <Wrap>
        <h1>什么是Bystack区块链</h1>
        <p>Bystack是区块链一站式的服务平台，为企业提供专业、优质的区块链技术解决方案，快速构建和实现数据区块链化。</p>
        <p>Bystack区块链具有以下优势：</p>
        <h2>成熟的智能合约</h2>
        <p>Bystack采用兼容EVM的智能合约，图灵完备，可灵活实现业务逻辑，具有高安全性和可靠性，在业界广泛应用。</p>
        <h2>高效稳定</h2>
        <p>
          Bystack区块链采用BFT+PoS共识，采用独创的一主多侧协同工作模型，高效运行规模化区块链应用，保障区块链系统的安全和稳定。
        </p>
        <h2>快速接入</h2>
        <p>面向不同规模的企业和机构，提供一站式的区块链解决方案，以及高效易用的区块链服务和配套开发工具</p>
        <h2>应用广泛</h2>
        <p>适用广泛的应用场景，提供丰富的可插拔服务，以及完整的应用生态，支持一键发布区块链应用</p>
        <img src={imgBystack} alt="bystack" />
        <p>Bystack为无界版图搭建专有区块链，并设置专门的网关来屏蔽底层细节，无界版图只需关注业务即可。</p>
        <h2>地址生成</h2>
        <p>无界版图为用户在区块链上生成唯一标记，作为用户身份的辅助标记，同时用于用户NFT资产归属和流转</p>
        <h2>版权发行</h2>
        <p>无界版图艺术家的版权都会通过Bystack在链上生成唯一对应的资产</p>
        <h2>版权上链</h2>
        <p>
          无界版图所有的版权信息都会上链（包括图片哈希值），利用区块链防篡改的特性，链上的版权信息为司法取证提供了非常可靠的证据。
          同时Bystack与杭州公证处，杭州市互联网法院打通，后期可为创作者提供一站式公证和维权服务
        </p>
        <h2>版权流转</h2>
        <p>
          在无界版图上进行的版权流转都会对应于区块链上的交易，交易不可篡改，可追随，公开。让无界版图上的每一笔版权流转都有迹可循
        </p>
      </Wrap>
    </Layout>
  );
};

export default Article;
